/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'tropical-storm': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 9.5a2 2 0 100-4 2 2 0 000 4"/><path pid="1" d="M9.5 2c-.9 0-1.75.216-2.501.6A5 5 0 0113 7.5a6.5 6.5 0 11-13 0 .5.5 0 011 0 5.5 5.5 0 008.001 4.9A5 5 0 013 7.5a6.5 6.5 0 0113 0 .5.5 0 01-1 0A5.5 5.5 0 009.5 2M8 3.5a4 4 0 100 8 4 4 0 000-8"/>',
    },
});
